import React from "react"
import styled from "styled-components"
import {lineColor} from "./../../../style/color"

export type LineProps = {
    style: StyledLineProps,
}

export type StyledLineProps = {
    lineWidth: number,
    lineHeight: number,
    lineColor:keyof typeof lineColor
}

export const Line: React.FC<LineProps> = function (props) {
    const { style } = props
    return (
        <StyledLine {...style}>
        </StyledLine>
    )
}


const StyledLine= styled.p<StyledLineProps>(props => `
    width: ${props.lineWidth + "px"};
    height: ${props.lineHeight + "px"};
    background: ${lineColor[props.lineColor]};
    border-radius: 20%;
    margin-right: 2px;
    margin-left: 1px;
`)

