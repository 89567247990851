import styled from 'styled-components';
export const Wrapper = styled.div`
  display:flex;
  width: 3840px;
  height: 2160px;
  /* min-height: 100vh;
  min-width: 100vw; */
`

export const DispPlaceDetailWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  /* justify-content: ; */
`