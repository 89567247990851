import React from "react"
import styled from "styled-components"
import { fontSize,fontFamily } from '../../../style/Font'
import {fontColor} from '../../../style/color'

export type TextBoxProps = {
    text: string,
    style: StyledTextBoxProps,
}

export type StyledTextBoxProps = {
    width: number | "fit-content",
    height: number,
    sidePadding?: number,
    fontSize: keyof typeof fontSize,
    fontColor: keyof typeof fontColor,
    fontFamily: keyof typeof fontFamily,
    bgColor: string,
    bold?: boolean,
    border?: string,
    radius?:number
}


const TextBox: React.FC<TextBoxProps> = function (props) {
    const { text, style } = props

    return (
        <StyledTextBox {...style}>
            {text}
        </StyledTextBox>
    )
}

export default TextBox

const StyledTextBox = styled.div<StyledTextBoxProps>(props => `
display: flex;
justify-content: center;
align-items: center;
border-radius: ${props.radius !== undefined ? props.radius : props.height / 2}px;
width: ${props.width !== "fit-content" ? props.width+"px" : "fit-content"};
height: ${props.height}px;
padding: 0px ${props.sidePadding !== undefined ? props.sidePadding : 0}px;
font-size: ${fontSize[props.fontSize] + "px"};
font-weight: ${props.bold ? "bold" : "normal"};
font-family: ${fontFamily[props.fontFamily]};
background-color: ${props.bgColor};
color: ${fontColor[props.fontColor]};
white-space: pre-line;
border: 2px solid ${props.border ? props.border : "none"};
`)