import styled from "styled-components"

type DefineColor = {
    color: string
}
type SizeProps = {
    width: number,
    height: number
}
export const Wrapper = styled.div<SizeProps>(props => `
    display: flex;
    width: ${props.width}px;
    height: 100%;
`)

export const DateWrapper = styled.div<DefineColor>(props => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${props.color};
`)

export const MonthDayWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`

export const ImageWrapper = styled.div`
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`