import {Grid,GridArea} from "../../../style/Grid"
import TextBox from "../../Atoms/TextBox"
import Image from "../../Atoms/Image"
import {styleColor,styleColorWeather,comfortColor} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {Wrapper} from "./styledComponents"
import {backgroundImg} from "../../../style/image"
type BackgroundProps = {
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const Background:React.FC<BackgroundProps> = (props) => {
    const {weather,time}={...props}
    const timetype = TimeZone[time] as string
    const weatherTyep = Weather[weather] as string
    return (
        <Wrapper>
            <Image
                url={backgroundImg[weatherTyep][timetype]}
                width={3840}
                height={2160}
                shape="SQUARE"
                isFitContent={true}
            />
        </Wrapper>
    )
}

export default Background