import {Grid,GridArea} from "../../../style/Grid"
import Image from "../../Atoms/Image"
import {mapImg} from "../../../style/image"
import {TimeZone,Weather} from "../../../states/utilState"
import {OutWrapper} from "./styledComponents"
import {BlurWrapper} from "./styledComponents"
type MapImageProps = {
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const MapImage:React.FC<MapImageProps> = (props) => {
    const {weather,time}={...props}

    const timetype = TimeZone[time] as string
    const weatherTyep = Weather[weather] as string
    // const obj:styleColorWeather  = styleColor[weatherTyep]
    // obj[timetype]
    // const sd: keyof styleColorWeatherTime = obj[timetype] as keyof styleColorWeatherTime
    return (
        <OutWrapper>
            <Image
                url={mapImg.floor3map}
                width={2506}
                height={1237}
                isFitContent={false}
                shape="SQUARE"
            />
            {/* <BlurWrapper/> */}
        </OutWrapper>
    )
}

export default MapImage