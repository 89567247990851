import { Store, combineReducers } from 'redux'
import officeDataSlice,{initialState as officeDataInitialState} from "./officeData/slice"

export const rootReducer = combineReducers({
    officeData: officeDataSlice,
})

export const preloadedState = () => {
    return {
        officeData: officeDataInitialState,
    }
}

export type StoreState = ReturnType<typeof preloadedState>

export type ReduxStore = Store<StoreState>