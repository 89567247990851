import styled from "styled-components"

export const OutChiledContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* height: 110px; */
    height:100%;
`

export const OutTextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 110px; */
    height:100%;
`