import {Grid,GridArea} from "../../../style/Grid"
import Text from "../../Atoms/Text"
import Image from "../../Atoms/Image"
import {styleColor,styleColorWeather,comfortColor} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {DateWrapper, MonthDayWrapper,Wrapper, ImageWrapper} from "./styledComponents"
import {specialImg} from "../../../style/image"
type BackgroundProps = {
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const dateStr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri","Sat"]
const DateLogo:React.FC<BackgroundProps> = (props) => {
    const {weather,time}={...props}
    const timetype = TimeZone[time] as string
    const weatherTyep = Weather[weather] as string
    const dateData = new Date()
    const year = dateData.getFullYear()
    const month = dateData.getMonth()+1
    const day = dateData.getDay()
    const date = dateData.getDate()
    return (
        <Wrapper height={500} width={346}>
            <Grid
                columns={["346px"]}
                rows={
                    [
                        "356px",
                        "1fr"
                    ]
                }
                areas={[
                    ["date-date-logo"],
                    ["logo-date-logo"]
                ]}
            >
                <GridArea name="date-date-logo">
                    <DateWrapper color={styleColor[weatherTyep][timetype].dateBox}>
                        <Text
                            text={String(year)}
                            style={{
                                fontSize:"MEDIUM",
                                fontColor:"dark",
                                fontFamily:"Eng",
                                bold:true,
                                // elm:-0.1
                            }}
                            />
                        <MonthDayWrapper>
                            <Text
                                text={String(month)}
                                style={{
                                    fontSize:"LARGE",
                                    fontColor:"dark",
                                    fontFamily:"Eng",
                                    bold:true,
                                    // elm:-0.2,
                                    letterSpacing:-8
                                }}
                            />
                            <Text
                                text="/"
                                style={{
                                    fontSize:"NORMAL",
                                    fontColor:"dark",
                                    fontFamily:"Eng",
                                    bold:true,
                                    // elm:-0.3
                                }}
                            />
                            <Text
                                text={String(date)}
                                style={{
                                    fontSize:"LARGE",
                                    fontColor:"dark",
                                    fontFamily:"Eng",
                                    bold:true,
                                    // elm:-0.2,
                                    letterSpacing:-8
                                }}
                            />
                        </MonthDayWrapper>
                        <Text
                            text={dateStr[day]}
                            style={{
                                fontSize:"MEDIUM",
                                fontColor:"dark",
                                fontFamily:"Eng",
                                // elm:-0.1,
                                bold:true
                            }}
                            />
                    </DateWrapper>
                </GridArea>
                <GridArea name="logo-date-logo">
                    <ImageWrapper>
                        <Image
                            url={specialImg.companyLogo}
                            width={280}
                            height="fit-content"
                            isFitContent={false}
                            shape="SQUARE"
                        />
                    </ImageWrapper>
                </GridArea>
            </Grid>
        </Wrapper>
    )
}

export default DateLogo