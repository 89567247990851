import {Line} from "./../../Atoms/line"
import {Circle, CircleWrapper, OutWrapper, LineWrapper} from "./styledComponents"
import {lineColor} from "../../../style/color"

type StateOfComfortProps = {
    comfortLevel: number,
    noisyLevel: number,
    size: number, // 100px以上
}
const StateOfComfort:React.FC<StateOfComfortProps> = (props) => {
    let color : string
    let colorGrade :string
    let colorLine :keyof typeof lineColor
    let line = (props.size/40) > 2 ? (props.size/40) : 2
    const noNoisy = () => {
        return (
        <div style={{background:"#ffffff",borderRadius:"50%"}}>
            <Circle size={props.size/3*2} line={line} colorStyle={{color:color, gradetionColor:colorGrade}} />
        </div>
        )
    }

    const noisy = () => {
        return <CircleWrapper size={props.size} line={line}>
                    <CircleWrapper size={props.size-(line*4)} line={line}>
                            <div style={{background:"#ffffff",borderRadius:"50%"}}>
                        <Circle size={props.size/3*2} line={line} colorStyle={{color:color, gradetionColor:colorGrade}}>
                                <LineWrapper gap={line}>
                                    <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                                        <Line style={{lineWidth:line,lineHeight:props.size/5,lineColor:colorLine}}> </Line>
                                    </div>
                                    <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                                        <Line style={{lineWidth:line,lineHeight:props.size/10,lineColor:colorLine}}> </Line>
                                    </div>
                                    <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                                        <Line style={{lineWidth:line,lineHeight:props.size/6,lineColor:colorLine}}> </Line>
                                    </div>
                                    <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                                        <Line style={{lineWidth:line,lineHeight:props.size/10,lineColor:colorLine}}> </Line>
                                    </div>
                                    <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                                        <Line style={{lineWidth:line,lineHeight:props.size/5,lineColor:colorLine}}> </Line>
                                    </div>
                                </LineWrapper>
                            </Circle>
                        </div>
                    </CircleWrapper>
                 </CircleWrapper>
    }

    const CheckConfort = () => {
        switch (props.comfortLevel){
            case 1:
                color="#FFB300"
                colorGrade="#9B4E00"
                colorLine="black"
                break;
            case 2:
                color="#EC0000"
                colorGrade="#5C0000"
                colorLine="white"
                break;
            case -1:
                color="#13B4EF"
                colorGrade="#043F55"
                colorLine="white"
                break;
            case -2:
                color="#116EB4"
                colorGrade="#00213A"
                colorLine="white"
                break;
            default:
                color="#A4F911"
                colorGrade="#6F8111"
                colorLine="black"
        }
        if(props.noisyLevel<1){
            return noNoisy()
        }else if(props.noisyLevel>0){
            return noisy()
        }
    }

    return (
        <OutWrapper size={props.size}>
              {CheckConfort()}
        </OutWrapper>
    )
}

export default StateOfComfort
