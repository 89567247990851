import DispSensorData from "../../Molecules/DispSensorData"
import Image from "../../Atoms/Image"
import {DisplayPlaceDetailWrapper, PlaceDataWrapper, MapImageWrapper,PlaceDataContainer,PlaceDataComponentWrapper,ContentsWrapper} from "./styledComponents"
import {SensorData} from "../../../states/utilState"
import {styleColor,styleColorWeather} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {lightIcon,darkIcon,IconType} from "../../../style/image"
import Widget from "../../Atoms/Widget"
import {Grid, GridArea} from "../../../style/Grid"
import MapImage from "../../Molecules/MapImage"
import ComfortMap from "../ComfortMap"
import StateOfComfort from "../../Molecules/StateOfComfort"
import Text from "../../Atoms/Text"
type DataTableProps = {
    placeData:SensorData[],
    nowPlace: number,
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const Map:React.FC<DataTableProps> = (props) => {
    const {placeData, weather, nowPlace, time} = {...props}
    // console.log("map now place parent",nowPlace)
    return (
        <DisplayPlaceDetailWrapper >
            <ContentsWrapper>
                <MapImageWrapper>
                    <MapImage
                        weather={props.weather}
                        time={props.time}
                        />
                </MapImageWrapper>
                <PlaceDataWrapper>
                    <ComfortMap {...props}/>
                </PlaceDataWrapper>
            </ContentsWrapper>
        </DisplayPlaceDetailWrapper>
    )
}

export default Map