import DispSensorData from "../../Molecules/DispSensorData"
import Image from "../../Atoms/Image"
import {DescriptionWrapper, OutWrapper,DescComfortWrapper,OutDescComfortWrapper,DescNoiseWrapper,LineWrapper,SpaceBetweenState,PlaceNameWrapper} from "./styledComponents"
import {SensorData} from "../../../states/utilState"
import {styleColor,styleColorWeather} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {lightIcon,darkIcon,IconType} from "../../../style/image"
import StateOfComfort from "../../Molecules/StateOfComfort"
import Text from "../../Atoms/Text";
import {Line} from "./../../Atoms/line"
import {lineColor} from "../../../style/color"

type FooterProps = {
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}

const CircleSize = 114
const Footer:React.FC<FooterProps> = (props) => {
    const { weather, time} = {...props}
    return (
        <OutWrapper >
            <DescriptionWrapper>
                <OutDescComfortWrapper>
                    <PlaceNameWrapper>
                        <Text
                            text="体感温度"
                            style={{
                                fontSize:"SMALLEST",
                                fontColor:"dark",
                                fontFamily:"Eng",
                                // elm:0.4,
                                bold:true
                            }}
                        />
                    </PlaceNameWrapper>
                    <DescComfort level={-2} label="寒い"/>
                    <SpaceBetweenState/>
                    <DescComfort level={-1} label="肌寒い"/>
                    <SpaceBetweenState/>
                    <DescComfort level={-0} label="快適"/>
                    <SpaceBetweenState/>
                    <DescComfort level={1} label="やや暑い"/>
                    <SpaceBetweenState/>
                    <DescComfort level={2} label="暑い"/>
                </OutDescComfortWrapper>
                <DescNoiseWrapper>
                    <NoiseBar />
                    <Text
                        text="音あり"
                        style={{
                            fontSize:"SMALLEST",
                            fontColor:"dark",
                            fontFamily:"Eng",
                            // elm:0.4,
                            bold:true
                        }}
                    />
                </DescNoiseWrapper>
            </DescriptionWrapper>
        </OutWrapper>
    )
}

export default Footer

type DescComfortPropsType = {
    label: string,
    level: number
}
const DescComfort: React.FC<DescComfortPropsType> = (props) => {
    return (
        <DescComfortWrapper>
            <StateOfComfort
                comfortLevel={props.level}
                noisyLevel={0}
                size={CircleSize}
            />
            <Text
                text={props.label}
                style={{
                    fontSize:"SMALLEST",
                    fontColor:"dark",
                    fontFamily:"Eng",
                    // elm:0.4,
                    bold:true
                }}
            />
        </DescComfortWrapper>
    )
}

const NoiseBar:React.FC = () => {
    const line = 5
    const NoizeSize = CircleSize * 2.1
    return (
        <LineWrapper gap={line * 2}>
            <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                <Line style={{lineWidth:line,lineHeight:NoizeSize/5,lineColor:"white"}}> </Line>
            </div>
            <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                <Line style={{lineWidth:line,lineHeight:NoizeSize/10,lineColor:"white"}}> </Line>
            </div>
            <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                <Line style={{lineWidth:line,lineHeight:NoizeSize/6,lineColor:"white"}}> </Line>
            </div>
            <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                <Line style={{lineWidth:line,lineHeight:NoizeSize/10,lineColor:"white"}}> </Line>
            </div>
            <div style={{ display: "flex",justifyContent:"center",alignItems:"center"}}>
                <Line style={{lineWidth:line,lineHeight:NoizeSize/5,lineColor:"white"}}> </Line>
            </div>
        </LineWrapper>
    )
}