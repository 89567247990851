import { createSlice } from '@reduxjs/toolkit'

import { OfficeDataState } from '../../states/utilState'
import { fetchOfficeData } from './asyncAction'

export const initialState: OfficeDataState = {
    sensorDatas: [],
    weather: 0,
    timeZone: 0,
    isDataexist: false
}

const officeDataSlice = createSlice({
    name: 'officeData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOfficeData.fulfilled, (state, action) => {
            // console.log("call action");
            if (action.payload !== undefined) {
                // console.log("ineed");
                state.sensorDatas = action.payload.sensorInfo.map((data)=>{
                    return {
                        placeName: data.placeName,
                        temp: data.temp,
                        hum: data.hum,
                        comfort: data.comfort,
                        volume: data.volume,
                        bright: data.light,
                        co2: data.co2
                    }
                })
                state.weather = action.payload.weather
                state.timeZone = action.payload.timeZone
                state.isDataexist = true
            }
        })
    }
})

export const {} = officeDataSlice.actions

export default officeDataSlice.reducer

