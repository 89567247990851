import styled from "styled-components"

type DefineColor = {
    color: string
    gradetionColor:　string
}

type DefineCicleWrapper = {
    size:number
    line:number
}

type OutWrapper = {
    size: number
}
export const OutWrapper = styled.div<OutWrapper>(props => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${ props.size + "px"};
    height: ${props.size + "px"};
    /* background-color: #000; */
`)

type CircleProps = {
    size: number,
    line: number,
    colorStyle: DefineColor
}
export const Circle =styled.div<CircleProps>(props => `
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: ${props.size + "px"};
height: ${props.size + "px"};
background: linear-gradient(-180deg, ${props.colorStyle.color}, ${props.colorStyle.gradetionColor});
border-radius: 50%;
border: ${props.line}px solid #fff;
box-sizing:border-box;
`)

export const CircleWrapper = styled.div<DefineCicleWrapper>(props => `
display: flex;
align-items: center;
justify-content: center;
width: ${props.size}px;
height: ${props.size}px;
background: "#ffffff";
border-radius: 50%;
border: ${props.line}px solid #fff;
box-sizing:border-box;
`)
// background-color: "#ffffff";

type LineWrapperPorps = {
    gap: number
}
export const LineWrapper =styled.div<LineWrapperPorps>(props => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    gap: ${props.gap}px;
    box-sizing:border-box;
`)
