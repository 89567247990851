export enum fontSize {
    SMALLEST = 39,
    SMALL = 50,
    MEDIUM = 56,
    NORMAL = 76,
    LARGER = 85,
    LARGE = 105,
    BIGGER = 235,
}

export enum fontFamily {
    Eng = 'Dosis',
    Ja = 'Helvetica Neue'
}
