import React from 'react';
import styled from 'styled-components'

export enum ImageShape {
    ROUND = 50,
    SQUARE = 0,
    RADIUS = 10
}

export type ImageProps = {
    url: string,
    width: number | "auto" | "fit-content",
    height: number | "auto" | "fit-content",
    isFitContent: boolean,
    shape: keyof typeof ImageShape
}

const Image: React.FC<ImageProps> = (props) => {
    var width: number | "auto" | "fit-content" = props.width
    var height: number  | "auto" | "fit-content"= props.height
    var shape: number = props.shape ? ImageShape[props.shape] : 100
    return (
        <StyledButtons src={props.url} height={height} width={width} shape={shape} isFitContent={props.isFitContent} />
    );
}

export default Image;

export type ImageStyleProps = {
    height: number | "auto" | "fit-content",
    width:number | "auto" | "fit-content",
    isFitContent:boolean,
    shape: number
}

export const StyledButtons = styled.img<ImageStyleProps>(props => `
  object-fit: ${props.isFitContent ?"cover":""};
  width: ${typeof props.width === "number" ? props.width+"px" : props.width};
  height: ${typeof props.height === "number" ? props.height+"px" : props.height};
  border-radius: ${props.shape}%;
`)
