import React from 'react'

import MainTemplate from '../Template/MainTemplate'

const Main: React.FC = () => {
    return (
        <MainTemplate>
        </MainTemplate>
    )
}

export default Main