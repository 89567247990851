import styled from "styled-components"

type DisplayPlaceDetailWrapperProps = {
    bgColor: string
}
export const OutWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 108px;
    width: 100%;
    height: 100%;
`

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: 138px;
    align-items: center;
`

export const OutDescComfortWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
`

export const DescComfortWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 136px;
    width: fit-content;
    align-items: center;
`

export const DescNoiseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 136px;
    width: fit-content;
    align-items: center;
    margin-left: 60px;
`

type LineWrapperPorps = {
    gap: number
}
export const LineWrapper =styled.div<LineWrapperPorps>(props => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    gap: ${props.gap}px;
    box-sizing:border-box;
`)

export const SpaceBetweenState = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #A2A2A2;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    align-items: center;
    margin: 36px 0px 80px;
`

export const PlaceNameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 36px;
`