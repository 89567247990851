import { Type } from "typescript"
import {Weather} from "../states/utilState"
// 色の指定はrgbかつ16進数の表現形式でお願いします。(ex: #1faa23)
// 透明度を指定したい場合は下記のリンクを参考に16真数で指定し、
// rgbの16進数表記の後ろに追加してください(ex: #1faa23cc)
// https://qiita.com/CUTBOSS/items/6166cf79b9f18c45a010

// 体感温度(不快指数)を表現する色
// 寒い・肌寒い・良い・やや暑い・暑い
export const comfortColor={
    dangerHeat:"#EC0000", // 暑い
    attenstionHeat:"#FFB300", // やや暑い
    normal:"#A4F911", // 良い
    attentionCold:"#13B4EF", // 肌寒い
    dangerCold:"#116EB4" // 寒い
}

export const comfortColorGradation={
    dangerHeat:"#5C0000", // 暑い
    attenstionHeat:"#9B4E00", // やや暑い
    normal:"#6F8111", // 良い
    attentionCold:"#043F55", // 肌寒い
    dangerCold:"#00213A" // 寒い
}

// 線のカラー指定
export  const lineColor ={
    white:"#ffffffBF",
    black:"#00000066"
}

// フォントカラーの指定
export enum fontColor {
    dark= "#ffffff", //暗い背景の時に使用するフォントカラー（白など）
    light="#000",
    green="#4CBF00",
    blue="#22B0F1"
}

type styleColorWeatherTime ={
    dateBox: string,
    box:string,
    bg: string,
    loadLine: string
}
export type styleColorWeather = {
    [key:string]:styleColorWeatherTime,
    dayTime:styleColorWeatherTime,
    evening:styleColorWeatherTime,
    night:styleColorWeatherTime
}

// 各時間帯や天気に合わせた配色
export const styleColor: {[key: string]:styleColorWeather} = {
    [Weather.Sunny]: { // 晴れの日用
        dayTime: {
            dateBox: "#0265CC",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        evening: {
            dateBox: "#7D4F40",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        night: {
            dateBox: "#1E2A2D",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
    },
    [Weather.Cloud]: { // 曇りの日用
        dayTime: {
            dateBox: "#7C7C8E",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        evening: {
            dateBox: "#0265CC",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        night: {
            dateBox: "#0265CC",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
    },
    [Weather.Rain]: { // 雨の日用
        dayTime: {
            dateBox: "#3A86A2",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        evening: {
            dateBox: "#21557D",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        night: {
            dateBox: "#292A45",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
    },
    [Weather.Snow]: { // 雪の日用
        dayTime: {
            dateBox: "#7EBED7",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        evening: {
            dateBox: "#2E547E",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
        night: {
            dateBox: "#0F2244",
            box:"#4A87B2",
            bg: "#00000080",
            loadLine: "#E69900"
        },
    },
}

// 主要なテーマカラー
export const primaryColor = {
    sunny_dayTime: "#00000057"
}

// 色を選択するための変数
export const color = {
    comfort : comfortColor,
    font: fontColor,
    styleColor: styleColor,
    primaryColor: primaryColor
}