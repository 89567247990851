import {Weather, PlaceName} from "../states/utilState"
import refleshImage from "../image/areaReflesh.png"
import aareaMeeting1 from "../image/areaMeeting1.png"

// アイコンlight
import CO2GreenLight from "../image/light/co2/co2_green_light.svg"
import CO2OrangeLight from "../image/light/co2/co2_orange_light.svg"
import CO2RedLight from "../image/light/co2/co2_red_light.svg"
import soundVLowLight from "../image/light/sound/noise_very_low_light.svg"
import soundLowLight from "../image/light/sound/noise_low_light.svg"
import soundNormalLight from "../image/light/sound/noise_normal_light.svg"
import soundHighLight from "../image/light/sound/noise_high_light.svg"
import soundVHighLight from "../image/light/sound/noise_very_high_light.svg"
import blightDarkLight from "../image/light/blight/light_dark_light.svg"
import blightNormalLight from "../image/light/blight/light_normal_light.svg"
import blightHighLight from "../image/light/blight/light_high_light.svg"
import humLight from "../image/light/hum/hum_normal_light.svg"
import tempLight from "../image/light/temp/temp_green_light.svg"

// 背景
import sunny_dayTime from "../image/background/sunny_dayTime.svg"
import sunny_evening from "../image/background/sunny_evening.svg"
import sunny_night from "../image/background/sunny_night.svg"
import rain_dayTime from "../image/background/rain_dayTime.svg"
import rain_evening from "../image/background/rain_evening.svg"
import rain_night from "../image/background/rain_night.svg"
import cloud_dayTime from "../image/background/cloud_dayTime.svg"
import cloud_evening from "../image/background/cloud_evening.svg"
import cloud_night from "../image/background/cloud_night.svg"
import snow_dayTime from "../image/background/snow_dayTime.svg"
import snow_evening from "../image/background/snow_evening.svg"
import snow_night from "../image/background/snow_night.svg"

// エリアカバー画像
import meetingWindow from "../image/area/meeting_window.svg"
import meetingEnter from "../image/area/meeting_enter.svg"
import meetingSmall from "../image/area/meeting_small.svg"
import officeWindow from "../image/area/office_window.svg"
import officeCenter from "../image/area/office_center.svg"
import officeEnter from "../image/area/office_enter.svg"
import passage from "../image/area/passage.svg"

import forval_logo_title from "../image/FORVAL_TECHNOLOGY_logo.svg"
import floor3map from "../image/floor3map.svg"


// 特定の場所を強調するときにマップに被せる画像
export const palceImg:{[key:string]:string} = {
    [PlaceName.meetingWindow]: meetingWindow, //会議室窓側
    [PlaceName.meetingEnter]: meetingEnter, //会議室入り口
    [PlaceName.meetingSmall]: meetingSmall, //小会議室
    [PlaceName.officeWindow]: officeWindow, //執務室窓側
    [PlaceName.officeCenter]: officeCenter, //執務室中央
    [PlaceName.officeEnter]: officeEnter, //執務室入り口
    [PlaceName.passage]: passage, //
}
const allMap = {
    light : "https:~~~~", //日中、夕方時表示用のアイコン
    dark : "https:~~~~", //夜間表示用
}

type IconTypeLevel = {
    low:string,
    normal:string,
    high: string
}
export type IconType = {
    temp: string,
    hum: string,
    sound:soundLevTyep,
    blight:blightLevType,
    co2:co2LevType
}

export const co2TypeKey: {[key: number]: keyof co2LevType} = {
    [0]: "low",
    [1]: "normal",
    [2]: "high",
}
type co2LevType = {
    low: string,
    normal: string,
    high: string
}

export const blightTypeKey: {[key: number]: keyof blightLevType} = {
    [-1]: "low",
    [0]: "normal",
    [1]: "high",
}
type blightLevType = {
    low: string,
    normal: string,
    high: string
}

export const soundTypeKey: {[key: number]: keyof soundLevTyep} = {
    [-2]: "veryLow",
    [-1]: "low",
    [0]: "normal",
    [1]: "high",
    [2]: "veryHigh"
}
type soundLevTyep = {
    veryLow: string
    low: string,
    normal: string,
    high: string,
    veryHigh: string
}

export const lightIcon:IconType= {
    temp : "https:~~~~", // 温度
    hum : "https:~~~~", // 湿度
    sound :{
        veryLow:soundVLowLight,
        low:"https:~~~~",
        normal: "https:~~~~",
        high :"https:~~~~",
        veryHigh:""
    }, //音
    blight:{
        low:"https:~~~~",
        normal: "https:~~~~",
        high :"https:~~~~"
    }, // 明るさ
    co2 :{
        low:"https:~~~~",
        normal: "https:~~~~",
        high :"https:~~~~"
    }, // CO2
}


export const darkIcon:IconType  = {
    temp : tempLight, // 温度
    hum : humLight, // 湿度
    sound :{
        veryLow:soundVLowLight,
        low:soundLowLight,
        normal:soundNormalLight,
        high :soundHighLight,
        veryHigh:soundVHighLight
    }, //音
    blight:{
        low:blightDarkLight,
        normal: blightNormalLight,
        high :blightHighLight
    }, // 明るさ
    co2 :{
        low: CO2GreenLight,
        normal: CO2OrangeLight,
        high: CO2RedLight
    }, // CO2
}

export const icon = {
    light: lightIcon, //日中、夕方時表示用のアイコン
    dark: darkIcon //夜間時表示用のアイコン
}

export const specialImg = {
    companyLogo : forval_logo_title, // 会社のロゴ}
}

type BGImgType= {
    [key: string]:string,
    dayTime : string,
    evening : string,
    night : string,
}

const sunnyBGImg:BGImgType = {
    dayTime : sunny_dayTime, // 日中の背景画像
    evening : sunny_evening, // 夕方の背景画像
    night : sunny_night, // 夜間の背景画像
}

const cloudBGImg:BGImgType = {
    dayTime : cloud_dayTime, // 日中の背景画像
    evening : cloud_evening, // 夕方の背景画像
    night : cloud_night, // 夜間の背景画像
}

const rainBGImg:BGImgType = {
    dayTime : rain_dayTime, // 日中の背景画像
    evening : rain_evening, // 夕方の背景画像
    night : rain_night, // 夜間の背景画像
}

const snowBGImg:BGImgType = {
    dayTime : snow_dayTime, // 日中の背景画像
    evening : snow_evening, // 夕方の背景画像
    night : snow_night, // 夜間の背景画像
}

const sunnyMapImg = {
    dayTime : "https:~~~~", // 日中のマップ画像
    evening : "https:~~~~", // 夕方のマップ画像
    night : "https:~~~~", // 夜間のマップ画像
}

const cloudMapImg = {
    dayTime : "https:~~~~", // 日中のマップ画像
    evening : "https:~~~~", // 夕方のマップ画像
    night : "https:~~~~", // 夜間のマップ画像
}

const rainMapImg = {
    dayTime : "https:~~~~", // 日中のマップ画像
    evening : "https:~~~~", // 夕方のマップ画像
    night : "https:~~~~", // 夜間のマップ画像
}

const snowMapImg = {
    dayTime : "https:~~~~", // 日中のマップ画像
    evening : "https:~~~~", // 夕方のマップ画像
    night : "https:~~~~", // 夜間のマップ画像
}

const effectIcon = {
    water1:"https:~~~~", // 水滴種類1
    water2:"https:~~~~", // 水滴種類2
    //必要に応じて水滴の変数を追加
    cloud1:"https:~~~~", // 雲種類1
    //必要に応じて雲の変数を追加
    snow1:"https:~~~~", // 雪種類1
    //必要に応じて雪の変数を追加
    moon:"https:~~~~", // 月種類1
}

export const mapImg = {
    floor3map: floor3map
    // sunnyMapImg: sunnyMapImg, // 晴れの時の背景画像
    // cloudMapImg: cloudMapImg, // 曇りの時の背景画像
    // rainBGImg: rainMapImg, // 雨の時の背景画像
    // snowMapImg: snowMapImg, // 雪の時の背景画像
}

export const backgroundImg:{[key: string]: BGImgType} = {
    [Weather.Sunny]: sunnyBGImg, // 晴れの時の背景画像
    [Weather.Cloud]: cloudBGImg, // 曇りの時の背景画像
    [Weather.Rain]: rainBGImg, // 雨の時の背景画像
    [Weather.Snow]: snowBGImg, // 雪の時の背景画像
    // effectIcon:effectIcon // 画面を装飾している水玉などの画像
}

export const image = {
    place: palceImg,
    allMap: allMap,
    icon: icon,
    specialImg: specialImg,
    backgroundImg: backgroundImg,
    mapImg: mapImg
}