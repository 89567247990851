import styled from "styled-components"

export const OutWrapper = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: center;
    width: 100%;
    height: 100%; */
`

export const BlurWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 3840px;
    height: 1472px; */
    background: transparent;
    backdrop-filter: blur(25px);
    --webkit-backdrop-filter: blur(25px);
    /* background-color: rgba(0, 0, 0, 0.1); */
`