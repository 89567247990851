import styled from "styled-components"


export const SensorDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 190px;
    align-items: center;
`

export const MapImageWrapper = styled.div`
    position: absolute;
    z-index: -50;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
`

type DisplayPlaceDetailWrapperProps = {
    bgColor: string
}
export const DisplayPlaceDetailWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3840px;
    height: 1472px;
`

export const ContentsWrapper = styled.div`
    position: relative;
    width: 3840px;
    height: 1472px;
`

export const PlaceDataWrapper = styled.div`
position: absolute;
top:0px;
left: 0px;
display: flex;
flex-wrap: wrap;
align-items: center;
align-content: center;
justify-content: center;
width: 100%;
height: 1472px;
`
export const PlaceDataComponentWrapper = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
align-items: center;
align-content: center;
justify-content: flex-start;
width: 100%;
height: 900px;
`

export const PlaceDataContainer = styled.div`
display: flex;
align-items: center;
/* justify-content: center; */
width: 1150px;
height: 280px;
`