import {Grid,GridArea} from "../../../style/Grid"
import TextBox from "../../Atoms/TextBox"
import {styleColor,styleColorWeather} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {OutChiledContainer,OutTextWrapper} from "./styledComponents"

type DispSensorDataProps = {
    text: string,
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
    boxWidth:number,
    bold: boolean
}
const DispSensorData:React.FC<DispSensorDataProps> = (props) => {
    const {text,children,weather,time,boxWidth,bold}={...props}

    const timetype = TimeZone[time] as string
    const weatherTyep = Weather[weather] as string
    // const obj:styleColorWeather  = styleColor[weatherTyep]
    // obj[timetype]
    // const sd: keyof styleColorWeatherTime = obj[timetype] as keyof styleColorWeatherTime
    return (
        <Grid
            columns={[String(boxWidth+"px")]}
            rows={
                [
                    "70px",
                    "350px"
                ]
            }
            areas={[
                ["box-disp-sensor"],
                ["param-disp-sensor"]
            ]}
        >
            <GridArea name="param-disp-sensor">
                <OutChiledContainer>
                    {children}
                </OutChiledContainer>
            </GridArea>
            <GridArea name="box-disp-sensor">
                <OutTextWrapper>
                    <TextBox
                        text={text}
                        style={{
                            width: 340,
                            height: 80,
                            fontSize: 'SMALL',
                            fontColor: 'dark',
                            fontFamily: "Ja",
                            bold: bold,
                            bgColor: styleColor[weatherTyep][timetype].box,
                            radius: 20
                        }}
                    />
                </OutTextWrapper>
            </GridArea>
        </Grid>
    )
}

export default DispSensorData