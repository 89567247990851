import styled from "styled-components"

type DefineColor = {
    color: string
}
export const Wrapper = styled.div`
    position: absolute;
    z-index: -99;
    display: flex;
    width: 100%;
    height: 100%;
`

export const CircleWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 94px;
height: 94px;
background-color: "#00000011";
border-radius: 50%;
border: 1px solid #000;
`