import React, { useCallback, useEffect, useState } from 'react'

import {Grid, GridArea} from "../../style/Grid"
import {
    Wrapper,DispPlaceDetailWrapper
} from './styledComponents'

import Background from "../Organisms/BackGround"
import DateLogo from "../Organisms/DateLogo"
import {styleColor} from '../../style/color'
import TextBox from "../Atoms/TextBox"
import DispPlaceDetail from "../Organisms/DisplayPlaceDetail"
import { SensorData, TimeZone, TimeZoneTypeKey, Weather, WeatherTypeKey } from '../../states/utilState'
import DataTable from "../Organisms/DataTable"
import Map from "../Organisms/Map"
import Footer from "../Organisms/Footer"
import { useOfficeDataState } from '../../reducer/officeData/selectors'
import { useDispatch } from 'react-redux'
import { fetchOfficeData } from '../../reducer/officeData/asyncAction'
const placeData1 = {
    placeName:"執務室1",
    temp:32,
    hum:40,
    comfort:2,
    volume:{
        db: 48,
        level: 1,
        msg: ""
    },
    bright:{
        lx:20,
        level:1,
        msg:""
    },
    co2:{
        level:1,
        ppm:500,
        msg:""
    }
}

const placeData2 = {
    placeName:"リフレッシュルーム",
    temp:32,
    hum:40,
    comfort:0,
    volume:{
        db: 48,
        level: 0,
        msg: ""
    },
    bright:{
        lx:20,
        level:1,
        msg:""
    },
    co2:{
        level:1,
        ppm:500,
        msg:""
    }
}

const placeDatas:SensorData[] =[placeData1,placeData2,placeData2,placeData1,placeData2,placeData2,placeData2]

const MainTemplate: React.FC = () => {
    const temp = useOfficeDataState().officeData.isDataexist
    const officeDataState = useOfficeDataState().officeData
    const [officeData, setofficeData] = useState<SensorData[]>()
    const [nowPlace,setNowPlace] = useState(0)
    const [placeAmount, setPlaceAmount] = useState(0)
    // console.log("log",useOfficeDataState())
    const dispatch = useDispatch()
    var timer: NodeJS.Timeout
    var placeTimer: NodeJS.Timeout

    const polling = () => {
        timer = setTimeout(()=>{
            dispatch(fetchOfficeData())
            polling()
            setofficeData([...officeDataState.sensorDatas])
            setPlaceAmount(officeDataState.sensorDatas.length)
        }, 10 * 1000)
    }
    const nextPlaceTimer = useCallback((now: number) => {
        var next = now
        // console.log("place timer flag",officeDataState !== undefined,temp,officeDataState ,officeDataState.sensorDatas,placeAmount)
        if(officeDataState !== undefined && officeDataState.sensorDatas.length !== 0){
            // console.log("place timer check",officeDataState.sensorDatas.length)
            next = now < officeDataState.sensorDatas.length-1 ? now +1 : 0
        }
        placeTimer = setTimeout(()=>{
            setNowPlace(next)
            // console.log("place timer",next)
            nextPlaceTimer(next)
        }, 10 * 1000)
    },[placeAmount, temp])
    useEffect(()=>{
        dispatch(fetchOfficeData())
        polling()
        // console.log("use Effect")
        // console.log(officeDataState)
        return ()=> clearTimeout(timer)
    },[setofficeData,officeDataState.isDataexist])
    useEffect(()=>{
        nextPlaceTimer(nowPlace)
        return ()=> clearTimeout(placeTimer)
    },[placeAmount])

    const time: keyof typeof TimeZone=TimeZoneTypeKey[officeDataState.timeZone]
    const weather: keyof typeof Weather=WeatherTypeKey[officeDataState.weather]
    return (
        <Wrapper >
            <Background time={time} weather={weather}></Background>
            <Grid
                style={{zIndex:99}}
                columns={["454px","1fr"]}
                rows={[
                        "500px",
                        "1472px",
                        "1fr"
                    ]}
                areas={[
                    ["day-logo-main-template","disp-place-detil-main-template"],
                    ["map-main-template","map-main-template"],
                    ["footer-main-template","footer-main-template"]
                ]}
            >
                <GridArea name="day-logo-main-template">
                    <DateLogo time={time} weather={weather}/>
                </GridArea>
                <GridArea name="disp-place-detil-main-template">
                    <DispPlaceDetailWrapper>
                        {officeDataState !== undefined && officeDataState.sensorDatas.length !== 0 ?
                            <DispPlaceDetail
                                placeData={officeDataState.sensorDatas[nowPlace]}
                                nowPlace={nowPlace}
                                weather={weather}
                                time={time}
                            />
                            :
                            <div>now loading ...{officeDataState !== undefined }</div>
                        }
                    </DispPlaceDetailWrapper>
                </GridArea>
                <GridArea name="map-main-template">
                    {officeDataState !== undefined && officeDataState.sensorDatas.length !== 0 ?
                        <Map
                            placeData={officeDataState.sensorDatas}
                            nowPlace={nowPlace}
                            weather={weather}
                            time={time}
                        />
                        :
                        <div>now loading ...</div>
                    }
                </GridArea>
                <GridArea name="footer-main-template">
                    <Footer time={time} weather={weather}/>
                </GridArea>
            </Grid>
        </Wrapper>
    )
}

export default MainTemplate