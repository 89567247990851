import React from "react"
import styled from "styled-components"
import { fontSize,fontFamily } from '../../../style/Font'
import {fontColor} from '../../../style/color'

export type TextProps = {
    text: string,
    style: StyledTextProps,
}

export type StyledTextProps = {
    fontSize: keyof typeof fontSize,
    fontColor: keyof typeof fontColor,
    fontFamily: keyof typeof fontFamily,
    bold?: boolean,
    elm?:number,
    letterSpacing?:number
}

const Text: React.FC<TextProps> = function (props) {
    const { text, style } = props
    return (
        <StyledText {...style}>
            {text}
        </StyledText>
    )
}

export default Text

const StyledText = styled.p<StyledTextProps>(props => `
margin-block-start: ${props.elm !== undefined ? props.elm : 0}em;
margin-block-end: ${props.elm !== undefined ? props.elm : 0}em;
font-size: ${fontSize[props.fontSize] + "px"};
font-family: ${fontFamily[props.fontFamily]};
font-weight: ${props.bold ? "bold" : "normal"};
letter-spacing: ${props.letterSpacing !== undefined ? props.letterSpacing : 0}px;
color: ${fontColor[props.fontColor]};
word-break : break-all;
white-space: pre-line;
`)