import styled from "styled-components"


export const SmallerSensorDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: fit-content;
    align-items: center;
    justify-content: center;
`
export const SensorDataWrapper = styled.div<{height:number}>(props=>`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: ${props.height}px;
    width: fit-content;
    align-items: center;
`)

export const PlaceWrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 1094px;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
`

export const SensorDataParamWrapper = styled.div`
    display: flex;
    padding-left: 8px;
    /* justify-content: center; */
    align-items: flex-end;
`
export const SensorDataParamBiggerWrapper = styled.div`
    display: flex;
    padding-left: 8px;
    /* justify-content: center; */
    align-items: center;
`

export const MiniTextWrapper = styled.div`
    display: flex;
    height:65px;
    align-items: flex-end;
`

type OutWrapperBoxProps = {
    bgColor: string
}
export const DisplayPlaceDetailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 26px;
    height: 100%;
    margin-top: -36px;
    /* margin-top:36px; */
`

export const BiggerImgWrapper = styled.div`
display: flex;
width: fit-content;
height: 235px;
flex-direction: column;
justify-content: flex-end;
padding-bottom: 45px;
/* align-items: flex-start; */
/* margin-top:36px; */
`

export const OutWrapperBox = styled.div<OutWrapperBoxProps>(props =>`
    position: absolute;
    display: flex;
    width: 3278px;
    /* width: 820px; */
    height: 366px;
    /* height: 1384px; */
    padding: 0px 63px;
    border-radius: 12px;
    background-color: ${props.bgColor};
    box-sizing: border-box;
    backdrop-filter: blur(14px);
    --webkit-backdrop-filter: blur(14px);
`)
