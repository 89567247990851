import DispSensorData from "../../Molecules/DispSensorData"
import Image from "../../Atoms/Image"
import {
    PlaceDataContainer,
    PlaceDataComponentWrapper,
    PlaceDataContentWrapper,
    PlaceDataContent
} from "./styledComponents"
import {SensorData,PlaceNameTypeKey, PlaceName} from "../../../states/utilState"
import {styleColor,styleColorWeather} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {lightIcon,darkIcon,IconType} from "../../../style/image"
import Widget from "../../Atoms/Widget"
import {Grid, GridArea} from "../../../style/Grid"
import MapImage from "../../Molecules/MapImage"
import StateOfComfort from "../../Molecules/StateOfComfort"
import Text from "../../Atoms/Text"
import {palceImg} from "../../../style/image"
type DataTableProps = {
    placeData:SensorData[],
    nowPlace: number,
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const ComfortMap:React.FC<DataTableProps> = (props) => {
    const {placeData, weather, nowPlace,time} = {...props}
    // console.log("map now place", nowPlace)
    return (
        <PlaceDataComponentWrapper>
            {makePlaceDataComponent(placeData, nowPlace)}
        </PlaceDataComponentWrapper>
    )
}

export default ComfortMap

const makePlaceDataComponent = (datas: SensorData[], nowPlace: number) => {
    return datas.map((data, index)=>{
        return (
            <PlaceDataContainer {...PositionOfComfortPins[index]}>
                <PlaceDataContent>
                    <PlaceDataContentWrapper>
                        <StateOfComfort
                            comfortLevel={data.comfort}
                            noisyLevel={data.volume.level}
                            size={180}
                        />
                    </PlaceDataContentWrapper>
                    {
                        nowPlace === index ? 
                    <PlaceDataContentWrapper>
                        <Image
                            url={palceImg[PlaceName[PlaceNameTypeKey[index]]]}
                            width={SizeOfCoverImg[index]}
                            height="fit-content"
                            isFitContent={false}
                            shape="SQUARE"
                        />
                    </PlaceDataContentWrapper> 
                    :<></>
                    }
                </PlaceDataContent>
            </PlaceDataContainer>
        )
    })
}

// Positionはleft, rightのどちらかをnumberで指定、もう片方をautoで指定。
// topとbottomも同様に指定する。
export type Position = {
    left: number | "auto",
    right: number | "auto",
    top: number | "auto",
    bottom: number | "auto",
}

export const PositionOfComfortPins:{[key:number]: Position} = {
    [0]: {left: 1245, right: "auto", top: 1175, bottom: "auto"},
    [1]: {left: 2100, right: "auto", top: 1135, bottom: "auto"},
    [2]: {left: 1050, right: "auto", top: 870, bottom: "auto"},
    [3]: {left: 1190, right: "auto", top:440, bottom: "auto"},
    [4]: {left: 1950, right: "auto", top: 360, bottom: "auto"},
    [5]: {left: 2840, right: "auto", top: 350, bottom: "auto"},
    [6]: {left: 2070, right: "auto", top: 740, bottom: "auto"}
}

const SizeOfCoverImg:{[key:number]: number} = {
    [0]: 680,
    [1]: 480,
    [2]: 450,
    [3]: 720,
    [4]: 780,
    [5]: 480,
    [6]: 680
}